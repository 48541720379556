/* This component keeps getting tweaked and exists in different codebases with different html, so this is why so many vars! */

/* These are set in the TSX/JS template: 
   --_slide-timeofday-noon-index 
   --_slide-timeofday-morning-index 
   --_slide-timeofday-evening-index
   --_slide-timeofday-night-index
*/

.container {
  /* the `--_` prefix just helps to understand these vars are "scoped" to this component */

  /* z-indexs */
  --_zindex-safari-bug: 0; /* Safari has a bug where overflow:hidden isn't respected on border radius when a child psuedo element overflows, `z-index: 0` or `transform: translateZ(0)` fixes it */
  --_zindex-gradient: 1;
  --_zindex-header: 2;
  --_zindex-clouds: 5;
  --_zindex-satellites: 3;
  --_zindex-landscape: 6;
  --_zindex-cityscape: 7;
  --_zindex-carousel: 8;
  --_zindex-top: 10;

  /* Title/Desc outside of component */
  --_title-max-width-sm: 320px;
  --_title-max-width-md: 430px;
  --_title-max-width-lg: 500px;
  --_description-max-width-sm: 370px;
  --_description-max-width-md: 430px;
  --_description-max-width-lg: 500px;

  /* Generic */
  --_button-height: 48px; /* Cosmos button height */
  --_gutter: 10px; /* padding at edge of screen, only comes into play on very narrow screens */

  /* Card/Slide titles */
  --_slide-title-max-width: 320px;
  --_slide-title-font-size: var(--font-size-42px);
  --_slide-title-line-height: 1.05; /* The line height is use to calc min-height, must be a unitless like `1.05` */
  --_slide-title-min-height: calc(
    3em * var(--_slide-title-line-height, 1.15)
  ); /* use `em` units so min height is a multiple of current font-size times line-height, 1.15 is Normalise.css line height fallback */

  /* Sun/Moon */
  --_satellite-sm: 36px; /* the sun/moon */
  --_satellite-lg: 60px; /* the sun/moon */

  /* Characters */
  --_character-height-sm: 201px; /* the main cartoon character per slide (mobile) */
  --_character-height-lg: 335px; /* the main cartoon character per slide (desktop) */
  --_character-width-sm: 192px;
  --_character-width-lg: 320px;

  /* Backgrounds - this needs to refactored if you adjust max-widths */
  --_landscape-height-sm: 231px; /* Height of Landscape for mobile, roughly based on desktop char to background ratio. (--_character-height-sm / (--_character-height-lg / --_landscape-height-lg)) or (201 / (335 / 385) */
  --_landscape-height-lg: 392px; /* Height of Landscape when screen is full width */
  --_cityscape-height-sm: 325px; /* Height of Cityscape for mobile, roughtly based on desktop char to background ratio. (--_character-height-sm / (--_character-height-lg / --_cityscape-height-lg) + --_button-height) or (201 / (335 / 462) + 48)) */
  --_cityscape-height-lg: 471px;

  /* paddings/margins */
  --_header-padding-top-sm: 42px;
  --_header-padding-top-md: 84px;
  --_header-padding-bottom-sm: 78px;
  --_header-padding-bottom-md: 78px;
  --_header-padding-bottom-lg: 98px;
  --_footer-margin-bottom: 42px; /* bottom of the card */
  --_button-top-margin: 32px; /* space above the header */
  --_background-bottom-margin: calc(
    var(--_footer-margin-bottom) + var(--_button-top-margin)
  );

  /* Gradients */
  --_gradient-morning: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.43) 6.67%,
    #e7f2ff 50.91%,
    #daebf0 100%
  );
  --_gradient-noon: linear-gradient(
    180deg,
    #ffffff 20.83%,
    #caecff 66.48%,
    rgba(167, 223, 255, 0.53) 0%
  );
  --_gradient-evening: linear-gradient(
    180deg,
    rgba(243, 237, 219, 0) 6.67%,
    rgba(249, 210, 174, 0.31) 38.69%,
    rgba(251, 198, 159, 0.67) 69.4%
  );
  --_gradient-night: linear-gradient(
    180deg,
    rgba(16, 68, 98, 0) 6.67%,
    rgba(0, 41, 147, 0.257733) 38.37%,
    rgba(3, 33, 136, 0.69) 69.39%
  );

  /* The space under the character carousel to the bottom of the card - effects the bottom aligned backgrounds */
  --_cityscape-bottom-margin: 0; /* cityscape is absolutely positoned bottom to card, use this to tweak the position */
  --_landscape-bottom-margin: 170.5px; /* landscape is absolutely positoned bottom to the card, use this to tweak the position */
  --_gradient-bottom-margin: 0;

  /* on mobile this is the space between the card and the edge of the screen */
  --_max-width: var(--max-width-base); /* max-width of the carousel */

  /* usually same as the card max width */
  --_clouds-stage-width: var(--_max-width);

  /* CSS */
  position: relative;
  margin-top: 80px;
  margin-bottom: 80px;
  padding-right: var(--spacing-sides);
  padding-left: var(--spacing-sides);
}

@media (width >= 900px) {
  .container {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.inner {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--max-width-base);
}

/* .content-intro {
  padding-left: var(--_gutter);
  padding-right: var(--_gutter);
} */

.title {
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  max-width: var(--_title-max-width-sm);
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-28px);
  line-height: 1.3;
  font-family: var(--font-family-heading);
  text-align: center;
}

@media (width >= 650px) {
  .title {
    margin-bottom: 32px;
    max-width: var(--_title-max-width-md);
    font-size: var(--font-size-36px);
    line-height: 1.2;
  }
}

@media (width >= 1000px) {
  .title {
    max-width: var(--_title-max-width-lg);
    font-size: var(--font-size-48px);
  }
}

.description {
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  max-width: var(--_description-max-width-sm);
  color: var(--page-theme-contrast-color, var(--color-text-subtle));
  line-height: 1.5;
  text-align: center;
}

@media (width >= 650px) {
  .description {
    margin-bottom: 32px;
    max-width: var(--_description-max-width-md);
  }
}

@media (width >= 1000px) {
  .description {
    margin-bottom: 80px;
    max-width: var(--_description-max-width-lg);
    font-size: var(--font-size-21px);
    line-height: 1.7;
  }
}

.carousels {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: var(--_zindex-safari-bug); /* see notes on the variable */
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  max-width: var(--_max-width);
  overflow: hidden;
}

@media (width >= 1000px) {
  .carousels {
    border-radius: var(--radius-x-large);
  }
}

.header {
  position: relative;
  z-index: var(--_zindex-header);
  padding-top: var(
    --_header-padding-top-sm
  ); /* Use padding rather than margins for position internal clouds */

  padding-bottom: var(--_header-padding-bottom-sm);
}

@media (width >= 650px) {
  .header {
    padding-top: var(--_header-padding-top-md);
    padding-bottom: var(--_header-padding-bottom-md);
  }
}

@media (width >= 720px) {
  .header {
    padding-bottom: var(--_header-padding-bottom-lg);
  }
}

.satellites {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: var(--_zindex-header);
  margin-top: var(--_header-padding-top-sm);
  width: 100%;
}

@media (width >= 650px) {
  .satellites {
    margin-top: var(--_header-padding-top-md);
  }
}

.satellite-img {
  display: block;
  position: relative;
  transform: translateY(-50%);
  width: var(--_satellite-sm);
  height: var(--_satellite-sm);
}

@media (width >= 518px) {
  .satellite-img {
    width: var(--_satellite-lg);
    height: var(--_satellite-lg);
  }
}

.satellite {
  --_character-height: var(
    --_character-height-sm
  ); /* lets make a new Var to reduce copy + pasting this complicated CSS */

  --_satellite: var(--_satellite-sm);
  --_clamp-min: calc(0% + (var(--_satellite) / 2));
  --_clamp-max: calc(100% - (var(--_satellite) / 1));

  position: absolute;
  top: 0;
  z-index: var(--_zindex-satellites);
  transition: top 1s ease-in-out;
}

@media (width >= 518px) {
  .satellite {
    --_satellite: var(--_satellite-lg);
  }
}

@media (width >= 720px) {
  .satellite {
    --_character-height: var(--_character-height-lg);
  }
}

.satellite--moon {
  right: 16px; /* magic number - visually positioned */
}

.satellite--sun {
  left: 16px; /* magic number - visually positioned */
}

@media (width >= 440px) {
  .satellite--moon {
    right: 0;
  }

  .satellite--sun {
    left: 0;
  }
}

@media (width >= 518px) {
  .satellite--moon {
    right: calc(
      50% - var(--_satellite-lg) - (var(--_slide-title-max-width) / 2) -
        var(--_gutter)
    );
  }

  .satellite--sun {
    left: calc(
      50% - var(--_satellite-lg) - (var(--_slide-title-max-width) / 2) -
        var(--_gutter)
    );
  }
}

/* Sun */
.container--morning .satellite {
  /* overkill? We use clamp here to make sure the satellite can't go too high or too low in the sky if a timeOfDay has an unusally high number of slides */
  --_clamp-ideal: calc(
    (100% - (var(--_satellite) / 1)) -
      ((var(--_slide-timeofday-morning-index) - 1) * (var(--_satellite) / 2))
  );

  top: clamp(var(--_clamp-min), var(--_clamp-ideal), var(--_clamp-max));
}

.container--noon .satellite {
  --_clamp-ideal: calc(
    (0% + (var(--_satellite) / 2)) +
      ((var(--_slide-timeofday-noon-index) - 1) * (var(--_satellite) / 2))
  );

  top: clamp(var(--_clamp-min), var(--_clamp-ideal), var(--_clamp-max));
}

.container--evening .satellite {
  top: calc(100% + (var(--_character-height) / 2));
}

.container--night .satellite {
  top: calc(100% + (var(--_character-height) / 2));
}

/* Moon */
.container--morning .satellite--moon {
  top: calc(100% + (var(--_character-height) / 2));
}

.container--noon .satellite--moon {
  top: calc(100% + (var(--_character-height) / 2));
}

.container--evening .satellite--moon {
  top: calc(
    (100% - (var(--_satellite) / 1)) -
      ((var(--_slide-timeofday-evening-index) - 1) * (var(--_satellite) / 2))
  );
}

.container--night .satellite--moon {
  top: calc(
    (0% + (var(--_satellite) / 2)) +
      ((var(--_slide-timeofday-night-index) - 1) * (var(--_satellite) / 2))
  );
}

.carousel-stage {
  position: relative;
  padding-bottom: var(--_button-height);
  width: 100%;
}

.background-gradient-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--_zindex-gradient);
  margin-bottom: var(--_gradient-bottom-margin);
  width: 100%;
  height: 565px;
}

.background-gradient {
  position: absolute;
  opacity: 0;
  transition: all 1s linear;
  width: 100%;
  height: 100%;
}

.background-gradient--is-active {
  opacity: 1;
}

.background-gradient--morning {
  background: var(--_gradient-morning);
}

.background-gradient--noon {
  background: var(--_gradient-noon);
}

.background-gradient--evening {
  background: var(--_gradient-evening);
}

.background-gradient--night {
  background: var(--_gradient-night);
}

.background-clouds {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--_zindex-clouds);
  margin-bottom: var(--_gradient-bottom-margin);
  width: var(--_clouds-stage-width);
  height: 100%;
}

.background-cloud {
  --_cloud-width: 46px;
  --_cloud-count: 8;
  --_cloud-animation-duration: 400s; /* You could tweak per cloud... */
  --_cloud-animation-timing-function: linear; /* You could tweak per cloud... */

  position: absolute;
  top: var(--_cloud-top);
  animation-duration: var(--_cloud-animation-duration);
  animation-timing-function: var(--_cloud-animation-timing-function);

  /* prettier-ignore */
  animation-delay: calc((var(--_cloud-start-position-unitless) * var(--_cloud-animation-duration)) * -1);
  animation-iteration-count: infinite;
  animation-name: animateCloud;
  width: var(--_cloud-width);
  height: auto;
}

.background-cloud--1 {
  --_cloud-start-position-unitless: calc(
    1 / (var(--_cloud-count))
  ); /* in 0-1  */

  --_cloud-top: 16%;
}

.background-cloud--2 {
  --_cloud-start-position-unitless: calc(
    2 / (var(--_cloud-count))
  ); /* in 0-1 */

  --_cloud-top: 55%;
}

.background-cloud--3 {
  --_cloud-start-position-unitless: calc(
    3 / (var(--_cloud-count))
  ); /* in 0-1 */

  --_cloud-top: 29%;
}

.background-cloud--4 {
  --_cloud-start-position-unitless: calc(
    4 / (var(--_cloud-count))
  ); /* in 0-1 */

  --_cloud-top: 69%;
}

.background-cloud--5 {
  --_cloud-start-position-unitless: calc(
    5 / (var(--_cloud-count))
  ); /* in 0-1 */

  --_cloud-top: 33%;
}

.background-cloud--6 {
  --_cloud-start-position-unitless: calc(6 / var(--_cloud-count)); /* in 0-1 */
  --_cloud-top: 10%;
}

.background-cloud--7 {
  --_cloud-start-position-unitless: calc(7 / var(--_cloud-count)); /* in 0-1 */
  --_cloud-top: 62%;
}

.background-cloud--8 {
  --_cloud-start-position-unitless: calc(
    8 / (var(--_cloud-count))
  ); /* in 0-1 */

  --_cloud-top: 42%;
}

@keyframes animateCloud {
  0% {
    margin-inline-start: calc(var(--_cloud-width) * -1);
  }

  100% {
    margin-inline-start: var(--_clouds-stage-width);
  }
}

@media (prefers-reduced-motion: reduce) {
  .background-cloud {
    animation: none;
    margin-inline-start: calc(
      (var(--_cloud-start-position-unitless) * var(--_clouds-stage-width))
    );
  }
}

.backgrounds-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; /* hides the carousel overflow */
}

.backgrounds-wrapper--landscape {
  z-index: var(--_zindex-landscape);
  margin-bottom: var(--_landscape-bottom-margin);
}

.backgrounds-wrapper--cityscape {
  z-index: var(--_zindex-cityscape);
  margin-bottom: var(--_cityscape-bottom-margin);
}

/* HACK: Currently the background image has a faded transparency. Ideally we have a solid image, this div is just used a fill colour */
.backgrounds-wrapper--cityscape .background::after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #c4d6eb;
  width: 100%;
  height: 50%;
  content: "";
}

.backgrounds {
  display: flex;
}

.background {
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.background-image {
  display: block;
  object-fit: cover;
  user-select: none;
}

.backgrounds-wrapper--cityscape .background-image {
  height: var(--_cityscape-height-sm); /* Magic Number 325px */
}

.backgrounds-wrapper--landscape .background-image {
  height: var(--_landscape-height-sm); /* Magic Number 231px */
}

@media (width >= 720px) {
  .backgrounds-wrapper--cityscape .background-image {
    height: var(--_cityscape-height-lg); /* Magic Number 462px */
  }

  .backgrounds-wrapper--landscape .background-image {
    height: var(--_landscape-height-lg); /* Magic Number 385px */
  }
}

/* We have multiple Embla carousels overlapping each other to create a paralax(ish) effect.
   These overlaps scroll in different directions, Embla adds `dir="ltr/rtl"` to parent `div` to achieve this.
   Therefore, we must target the `html[dir=""]` (specify `html`) to get the true language direction. */
html[dir="rtl"] .backgrounds-wrapper--landscape .background-image {
  transform: scaleX(
    -1
  ); /* Background images have a seamless effect, so need to be flipped */
}

html[dir="rtl"] .backgrounds-wrapper--cityscape .background-image {
  transform: scaleX(
    -1
  ); /* Background images have a seamless effect, so need to be flipped */
}

/* This Title has aria-hidden applied */
.slide-title {
  display: grid; /* we don't want this element to change height when the content inside changes, so we will overlap all child elelments which forces a height */
  position: relative;
  z-index: var(--_zindex-top);
  padding-right: var(--_gutter);
  padding-left: var(--_gutter);
  max-width: calc(var(--_slide-title-max-width) + (var(--_gutter) * 2));
  min-height: var(--_slide-title-min-height);
  font-weight: 700;
  font-size: var(--_slide-title-font-size);
  line-height: var(--_slide-title-line-height);
  font-family: var(--ff-futura);
  text-align: center;
  text-transform: uppercase;
}

.slide-title-item {
  display: flex;
  grid-area: 1 / 1; /* shorthand for `grid-[column/row]-[start/end]` */
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 250ms;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.slide-title-item--is-active {
  transition-delay: 250ms;
  transition-duration: 250ms;
  transition-timing-function: ease-in;
}

/* Hidden sets `display: none` but we want this to be in the document flow to make sure the height stays the same as content changes, so set it's visibility to hidden. */
.slide-title-item[hidden] {
  display: flex; /* inherited specificity > browser default, but set this just to be sure */
  opacity: 0;
}

.carousel-wrapper {
  position: relative;
  z-index: var(--_zindex-carousel);
  margin-bottom: var(--_background-bottom-margin); /* 64px */
  width: 100%;
}

.carousel {
  width: 100%;
  overflow: hidden;
}

.carousel-slides {
  display: flex;
  position: relative;
}

.carousel-slide {
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33%;
  flex-direction: column;
  align-items: center;
}

.carousel-slide--not-active {
  pointer-events: none;
}

.carousel-slide--next-wiggle,
.carousel-slide--prev-wiggle {
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.carousel-slide--next-wiggle {
  animation-delay: 5s;
  animation-direction: alternate;
  animation-name: occasion-stack__carousel-slide--wiggle-next;
}

.carousel-slide--prev-wiggle {
  animation-delay: 6.5s;
  animation-direction: alternate;
  animation-name: occasion-stack__carousel-slide--wiggle-prev;
}

/* There probably is a better way to do this...
   We need to create an animation of a side-to-side wiggle, that winds up in intensity, and has ease-in-out between each wiggle.
   The side-to-side wiggle needs and ease-in-out tween, so it "soft bounces" off the left/right edges.
   The wiggles should "wind-up", 0 to -1, -1 to 2, 2 to -3, -3 to 3, 3 to -2, -2 to 1, 1 to 0.
   There should also be a pause between animations, CSS can't do that, you need to hard code it into the animation, maybe we can start/pause via a JS timer?
   So I ended up hard coding the tween easing to the animation, and running it with linear timing.
   Used this article to help, https://css-tricks.com/ease-y-breezy-a-primer-on-easing-functions/
   Especially this Codepen to work out the cubic-bezier values: https://codepen.io/pkodmad/pen/gQLyEd
   Modified the Codepen with `const x1 = -3; const x2 = 3; const duration = 10;` (or `x1 = 0` `x2 = -1` `duration = 5` to work each part of the animation)
*/
@keyframes occasion-stack__carousel-slide--wiggle-next {
  0% {
    transform: translateX(0);
  }

  1% {
    transform: translateX(-0.0816598562658975px);
  }

  2% {
    transform: translateX(-0.33188387009764625px);
  }

  3% {
    transform: translateX(-0.6681161299023539px);
  }

  4% {
    transform: translateX(-0.9183401437341026px);
  }

  5% {
    transform: translateX(-1px);
  }

  6% {
    transform: translateX(-0.9408326393550664px);
  }

  7% {
    transform: translateX(-0.7550204312023074px);
  }

  8% {
    transform: translateX(-0.43781227988406235px);
  }

  9% {
    transform: translateX(-0.0043483897070613065px);
  }

  10% {
    transform: translateX(0.5px);
  }

  11% {
    transform: translateX(1.0043483897070615px);
  }

  12% {
    transform: translateX(1.4378122798840627px);
  }

  13% {
    transform: translateX(1.7550204312023077px);
  }

  14% {
    transform: translateX(1.9408326393550666px);
  }

  15% {
    transform: translateX(2px);
  }

  16% {
    transform: translateX(1.901387732258444px);
  }

  17% {
    transform: translateX(1.5917007186705125px);
  }

  18% {
    transform: translateX(1.063020466473437px);
  }

  19% {
    transform: translateX(0.3405806495117687px);
  }

  20% {
    transform: translateX(-0.5px);
  }

  21% {
    transform: translateX(-1.3405806495117698px);
  }

  22% {
    transform: translateX(-2.0630204664734375px);
  }

  23% {
    transform: translateX(-2.5917007186705128);
  }

  24% {
    transform: translateX(-2.901387732258444);
  }

  25% {
    transform: translateX(-3px);
  }

  26% {
    transform: translateX(-2.8816652787101327px);
  }

  27% {
    transform: translateX(-2.510040862404615px);
  }

  28% {
    transform: translateX(-1.8756245597681247px);
  }

  29% {
    transform: translateX(-1.0086967794141226px);
  }

  30% {
    transform: translateX(0);
  }

  31% {
    transform: translateX(1.008696779414123px);
  }

  32% {
    transform: translateX(1.8756245597681254px);
  }

  33% {
    transform: translateX(2.5100408624046153px);
  }

  34% {
    transform: translateX(2.881665278710133px);
  }

  35% {
    transform: translateX(3px);
  }

  36% {
    transform: translateX(2.901387732258444);
  }

  37% {
    transform: translateX(2.5917007186705123);
  }

  38% {
    transform: translateX(2.063020466473437px);
  }

  39% {
    transform: translateX(1.3405806495117687px);
  }

  40% {
    transform: translateX(0.5px);
  }

  41% {
    transform: translateX(-0.3405806495117698px);
  }

  42% {
    transform: translateX(-1.0630204664734375px);
  }

  43% {
    transform: translateX(-1.5917007186705128px);
  }

  44% {
    transform: translateX(-1.9013877322584438px);
  }

  45% {
    transform: translateX(-2px);
  }

  46% {
    transform: translateX(-1.9408326393550663px);
  }

  47% {
    transform: translateX(-1.7550204312023074px);
  }

  48% {
    transform: translateX(-1.4378122798840622px);
  }

  49% {
    transform: translateX(-1.0043483897070613px);
  }

  50% {
    transform: translateX(-0.5px);
  }

  51% {
    transform: translateX(0.0043483897070615285px);
  }

  52% {
    transform: translateX(0.4378122798840627px);
  }

  53% {
    transform: translateX(0.7550204312023077px);
  }

  54% {
    transform: translateX(0.9408326393550666px);
  }

  55% {
    transform: translateX(1px);
  }

  56% {
    transform: translateX(0.9183401437341026px);
  }

  57% {
    transform: translateX(0.6681161299023537px);
  }

  58% {
    transform: translateX(0.3318838700976461px);
  }

  59% {
    transform: translateX(0.08165985626589745px);
  }

  60% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes occasion-stack__carousel-slide--wiggle-prev {
  0% {
    transform: translateX(0);
  }

  1% {
    transform: translateX(0.0816598562658975px);
  }

  2% {
    transform: translateX(0.33188387009764625px);
  }

  3% {
    transform: translateX(0.6681161299023539px);
  }

  4% {
    transform: translateX(0.9183401437341026px);
  }

  5% {
    transform: translateX(1px);
  }

  6% {
    transform: translateX(0.9408326393550664px);
  }

  7% {
    transform: translateX(0.7550204312023074px);
  }

  8% {
    transform: translateX(0.43781227988406235px);
  }

  9% {
    transform: translateX(0.0043483897070613065px);
  }

  10% {
    transform: translateX(-0.5px);
  }

  11% {
    transform: translateX(-1.0043483897070615px);
  }

  12% {
    transform: translateX(-1.4378122798840627px);
  }

  13% {
    transform: translateX(-1.7550204312023077px);
  }

  14% {
    transform: translateX(-1.9408326393550666px);
  }

  15% {
    transform: translateX(-2px);
  }

  16% {
    transform: translateX(-1.901387732258444px);
  }

  17% {
    transform: translateX(-1.5917007186705125px);
  }

  18% {
    transform: translateX(-1.063020466473437px);
  }

  19% {
    transform: translateX(-0.3405806495117687px);
  }

  20% {
    transform: translateX(0.5px);
  }

  21% {
    transform: translateX(1.3405806495117698px);
  }

  22% {
    transform: translateX(2.0630204664734375px);
  }

  23% {
    transform: translateX(2.5917007186705128);
  }

  24% {
    transform: translateX(2.901387732258444);
  }

  25% {
    transform: translateX(3px);
  }

  26% {
    transform: translateX(2.8816652787101327px);
  }

  27% {
    transform: translateX(2.510040862404615px);
  }

  28% {
    transform: translateX(1.8756245597681247px);
  }

  29% {
    transform: translateX(1.0086967794141226px);
  }

  30% {
    transform: translateX(0);
  }

  31% {
    transform: translateX(-1.008696779414123px);
  }

  32% {
    transform: translateX(-1.8756245597681254px);
  }

  33% {
    transform: translateX(-2.5100408624046153px);
  }

  34% {
    transform: translateX(-2.881665278710133px);
  }

  35% {
    transform: translateX(-3px);
  }

  36% {
    transform: translateX(-2.901387732258444);
  }

  37% {
    transform: translateX(-2.5917007186705128);
  }

  38% {
    transform: translateX(-2.0630204664734375px);
  }

  39% {
    transform: translateX(-1.3405806495117698px);
  }

  40% {
    transform: translateX(-0.5px);
  }

  41% {
    transform: translateX(0.3405806495117687px);
  }

  42% {
    transform: translateX(1.063020466473437px);
  }

  43% {
    transform: translateX(1.5917007186705125px);
  }

  44% {
    transform: translateX(1.901387732258444px);
  }

  45% {
    transform: translateX(2px);
  }

  46% {
    transform: translateX(1.9408326393550666px);
  }

  47% {
    transform: translateX(1.7550204312023077px);
  }

  48% {
    transform: translateX(1.4378122798840627px);
  }

  49% {
    transform: translateX(1.0043483897070615px);
  }

  50% {
    transform: translateX(0.5px);
  }

  51% {
    transform: translateX(-0.0043483897070613065px);
  }

  52% {
    transform: translateX(-0.43781227988406235px);
  }

  53% {
    transform: translateX(-0.7550204312023074px);
  }

  54% {
    transform: translateX(-0.9408326393550664px);
  }

  55% {
    transform: translateX(-1px);
  }

  56% {
    transform: translateX(-0.9183401437341026px);
  }

  57% {
    transform: translateX(-0.6681161299023539px);
  }

  58% {
    transform: translateX(-0.33188387009764625px);
  }

  59% {
    transform: translateX(-0.0816598562658975px);
  }

  60% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

/* Accessibility: lets remove the animation if users are sensitive to vestibular motion triggers. */
@media (prefers-reduced-motion: reduce) {
  .carousel-slide--prev-wiggle,
  .carousel-slide--next-wiggle {
    animation: none;
  }
}

.carousel-description {
  display: block;
  margin-top: 1em;
}

/* Visually Hidden, 
   todo: consider making a Visually Hidden component */
.carousel-description--sr-only,
.carousel-title--sr-only {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  white-space: nowrap;
}

.carousel-link {
  display: flex;
  flex-direction: column;
  color: var(--color-text); /* Fallback colours when images don't load */
  text-align: center;
  text-decoration: none;
}

.carousel-image {
  display: block;
  transform: scale(1);
  transform-origin: 50% 100%;
  transition: transform 500ms ease-in-out !important; /* Overwriting the CMS image transition */
  width: var(--_character-width-sm);
  height: var(--_character-height-sm);
  object-fit: contain;
  object-position: center bottom;
  user-select: none;
}

@media (width >= 720px) {
  .carousel-image {
    width: var(--_character-width-lg);
    height: var(--_character-height-lg);
  }
}

.carousel-image--small {
  transform: scale(0.6);
}

.button-wrapper {
  position: relative;
  transform: translateY(calc(var(--_footer-margin-bottom) * -1));
  z-index: var(--_zindex-top);
  margin-top: calc(var(--_button-height) * -1);
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

.slider-button {
  --_button-y-offset: calc(
    (var(--_background-bottom-margin) + var(--_button-height)) / 2
  ); /* visually centered */

  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - var(--_button-y-offset)));
  z-index: var(--_zindex-top);
  margin-right: 36px;
  margin-left: 36px;
}

@media (width >= 1152px) {
  .slider-button {
    display: block;
  }

  .slider-button--next {
    inset-inline-end: 0; /* same "Right" in LTR and RTL languages */
  }

  .slider-button--prev {
    inset-inline-start: 0; /* same "Left" in LTR and RTL languages */
  }

  html[dir="rtl"] .slider-button--next,
  html[dir="rtl"] .slider-button--prev {
    transform: scaleX(-1) translateY(calc(-50% - var(--_button-y-offset))); /* Flipped for RTL */
  }
}
